<!-- 使用说明 -->
<template>
  <div class="container instruction-page">
    <NavBar class="navbar" />
    <div class="swiper-box">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide>
          <img src="../../assets/images/s1.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/s2.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/s3.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/s4.png" />
          <van-button
            class="btn"
            color="#CB7E0E"
            round
            block
            @click="$router.push({ name: 'User' })"
            >立即开始</van-button
          >
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: 'instruction',
  components: {
    NavBar,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 15
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.instruction-page {
  .swiper-box {
    margin-top: 10px;
  }
  /deep/.swiper-container {
    height: 563px;
    .swiper-slide {
      width: 317px;
      img {
        width: 100%;
        height: 100%;
      }
      .btn {
        position: absolute;
        bottom: 15px;
        left: 50%;
        width: 173px;
        transform: translateX(-50%);
      }
    }
    .swiper-pagination-bullet {
      background-color: #ebedf0;
      opacity: 0.3;
      &-active {
        opacity: 1;
      }
    }
  }
}
</style>
